<template>
  <div class="column is-narrow-mobile">
    <div class="card">
      <header class="card-header is-flex is-flex--space-between">
        <div>
          <p class="card-header-title is-size-5 has-text-grey">{{ $t('seats.title') }}</p>
        </div>
        <div class="is-flex is-flex--center">
          <!-- <app-input-search @on-text-enter="searchFilteredUsers"></app-input-search> -->
          <a class="card-header-icon" aria-label="more options">
            <dir class="button is-accent is-small" @click="authorizeSeats">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>{{ $t('seats.auth_btn') }}</span>
            </dir>
          </a>
        </div>
      </header>
      <div class="card-content">
        <b-table :data="fb_seats" :columns="columns" :checked-rows.sync="checkedRows" checkable></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputSearch from '@/common/widgets/search/InputSearch'

export default {
  name: 'userTable',
  components: {
    'app-input-search': InputSearch
  },
  data() {
    return {
      UserId: null,
      fetchSuccess: false,
      modalActive: false,
      checkedRows: [],
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true
        },
        {
          field: 'name',
          label: 'Name',
          sortable: true
        },
        {
          field: 'clientId',
          label: 'Client Id',
          sortable: true
        },
        {
          field: 'seat',
          label: 'Seat Number'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['seats', 'fb_seats'])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  created() {
    if (this.$route.query.hasOwnProperty('q')) {
      // this.searchFilteredUsers({textToSearch: this.$route.query.q})
    } else {
      this.getSeats()
    }
  },
  methods: {
    ...mapActions(['getSeats', 'authorize_seats']),
    authorizeSeats() {
      if (this.checkedRows.length === 0) {
        this.$buefy.snackbar.open({ message: this.$t('seats.select_one'), type: 'is-danger' })
        return
      }
      this.authorize_seats(this.checkedRows).then(res => {
        this.$router.push({ name: 'seatConfirmation' })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
