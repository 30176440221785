var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column is-narrow-mobile" }, [
    _c("div", { staticClass: "card" }, [
      _c(
        "header",
        { staticClass: "card-header is-flex is-flex--space-between" },
        [
          _c("div", [
            _c(
              "p",
              { staticClass: "card-header-title is-size-5 has-text-grey" },
              [_vm._v(_vm._s(_vm.$t("seats.title")))]
            )
          ]),
          _c("div", { staticClass: "is-flex is-flex--center" }, [
            _c(
              "a",
              {
                staticClass: "card-header-icon",
                attrs: { "aria-label": "more options" }
              },
              [
                _c(
                  "dir",
                  {
                    staticClass: "button is-accent is-small",
                    on: { click: _vm.authorizeSeats }
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _c("i", { staticClass: "fas fa-plus" })
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.$t("seats.auth_btn")))])
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "card-content" },
        [
          _c("b-table", {
            attrs: {
              data: _vm.fb_seats,
              columns: _vm.columns,
              "checked-rows": _vm.checkedRows,
              checkable: ""
            },
            on: {
              "update:checkedRows": function($event) {
                _vm.checkedRows = $event
              },
              "update:checked-rows": function($event) {
                _vm.checkedRows = $event
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }